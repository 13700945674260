@import '~@which/seatbelt/src/styles/styles';

.downloadsWrapper {
  background-color: $sb-colour-background-subtle;
  margin-bottom: 30px;
  padding: 20px;
  width: 100%;
}

.linkWrapper {
  margin-bottom: $sb-spacing-m;

  &:last-child {
    margin-bottom: 0;
  }
}

.link {
  align-items: flex-start;
  color: $sb-colour-text-default;
  display: flex;
  font-family: $sb-font-family-regular;
  font-size: $sb-font-size-18;
  text-decoration: none;

  &:hover {
    .linkText {
      background-size: 100% 100%;
    }
  }
}

.linkText {
  @include sb-secondary-link;
  background-image: linear-gradient(
    transparent calc(100% - 1px),
    $sb-colour-text-default 1px
  );
  background-repeat: no-repeat;
  background-size: 0% 100%;
  transition: background-size 0.25s ease-out;
  width: calc(100%);
}

.downloadFileIcon {
  margin-right: $sb-spacing-xs;
}

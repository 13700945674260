@import '~@which/seatbelt/src/styles/styles';

.setSquareImage {
  width: 100%;

  article {
    height: 100%;
  }

  div[class^='ContentCard_cardImg'] {
    width: 72px;
    margin: 10px auto;

    @include sb-respond-to(medium-up) {
      width: 84px;
      margin: 30px auto;
    }
  }
}

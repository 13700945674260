@import '~@which/seatbelt/src/styles/styles';

.jumplinksBar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1002;
  background-color: $sb-colour-background-default;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  width: 100%;
  padding: $sb-spacing-m 0 calc($sb-spacing-m - 4px);
  transition: 0.4s ease;

  &Item {
    position: relative;

    &Dropdown {
      @include sb-respond-to(medium-up) {
        text-align: right;
      }
    }
  }

  &Label {
    padding: 0 0 $sb-spacing-xs;
    text-align: left;

    @include sb-respond-to(medium-up) {
      padding: 10px 0 0;
      text-align: right;
    }
  }
}

.dropdownContainer {
  height: 47px;
  max-width: 100%;
  position: relative;

  @include sb-respond-to(medium-up) {
    text-align: left;
  }
}

.selectedContainer {
  position: relative;
  padding: $sb-spacing-s 0 $sb-spacing-s $sb-spacing-m;

  [class*='CustomDropdown__selectedOption'] {
    border-right: solid 1px #ccc;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    white-space: nowrap;
  }

  [class*='CustomDropdown_selectedOption'] {
    border-right: solid 1px #ccc;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    white-space: nowrap;
  }

  [class*='CustomDropdown__chevronIcon'] {
    justify-self: center;
    margin: 0;
  }

  //SB-TODO - double underscore > single underscore
  [class*='CustomDropdown_chevronIcon'] {
    justify-self: center;
    margin: 0;
  }
}

.optionsContainer {
  z-index: 2;
  border-top: $sb-border-width-s solid $sb-colour-border-default;

  @include sb-respond-to(medium-up) {
    width: auto;
    border-top: none;
    left: auto;
  }
}

.gridContainer {
  max-width: 1440px;
  margin: 0 auto;
}

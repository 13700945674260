@import '~@which/seatbelt/src/styles/styles';

$maxCardWidth: 349px;

.headingWrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  margin-bottom: $sb-spacing-m;

  h2 {
    padding-bottom: 0;
    margin-right: $sb-spacing-m;
  }

  .relatedArticleLink {
    margin-bottom: $sb-spacing-s;
  }
}

.categoryTypesContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-flow: row;
  gap: $sb-spacing-m;
  margin-top: $sb-spacing-m;

  @include sb-respond-to(medium-up) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.categoryTypesContainerTwo {
  grid-template-columns: repeat(2, 1fr);

  @include sb-respond-to(medium-up) {
    grid-template-columns: repeat(2, $maxCardWidth);
  }
}

.categoryTypesContainerThree {
  grid-template-columns: repeat(2, 2fr);

  @include sb-respond-to(medium-up) {
    grid-template-columns: repeat(3, 1fr);
  }

  @include sb-respond-to(xlarge) {
    grid-template-columns: repeat(3, $maxCardWidth);
  }
}

@import '~@which/seatbelt/src/styles/styles';

.starRating {
  display: inline-block;
  padding: 0 $sb-spacing-m;

  .starRatingBadge {
    padding: 0 $sb-spacing-s;
    border-radius: $sb-border-radius-full;

    .starRatingNumber {
      font-family: $sb-font-family-bold;
    }

    .rating {
      display: flex;
      gap: $sb-spacing-xs;
    }
  }

  .oneStar {
    background-color: $sb-colour-background-disabled;
  }

  .twoStar {
    background-color: $sb-colour-background-brand-pink;
  }

  .threeStar {
    background-color: $sb-colour-background-brand-gold;
  }

  .fourStar {
    background-color: $sb-colour-background-brand-green;
  }

  .fiveStar {
    background-color: $sb-colour-border-positive;
    color: $sb-colour-text-inverse;
  }

  .visuallyHidden {
    @include sb-visually-hidden;
  }
}

@import '~@which/seatbelt/src/styles/styles';

.statisticsSection {
  &OuterWrapper {
    @include sb-respond-to(medium-up) {
      margin-top: $sb-spacing-xl;
    }

    margin-top: $sb-spacing-l;
    max-width: none;
  }

  &Wrapper {
    max-width: 1440px;
    width: 100%;
    justify-content: center;
    margin: 0 auto;
    padding: $sb-spacing-l $sb-spacing-l 43px;

    @include sb-respond-to(medium-up) {
      padding: 55px 46px 107px;
    }
  }

  &Text {
    @include sb-respond-to(medium-up) {
      padding-bottom: $sb-spacing-l;
    }
    padding-bottom: $sb-spacing-m;
    text-align: center;
  }

  &Header {
    max-width: 1110px;
    margin: 0 auto;
    text-align: center;

    h3 {
      padding-bottom: $sb-spacing-m;
    }

    p {
      max-width: 890px;
      margin: 0 auto;
      padding: 0 $sb-spacing-xl;
    }
  }

  &Content {
    margin-top: $sb-spacing-l;

    @include sb-respond-to(medium-up) {
      display: flex;
      margin-top: $sb-spacing-xl;
    }
  }

  &PictureWrapper {
    margin-bottom: $sb-spacing-m;

    @include sb-respond-to(medium-up) {
      margin-bottom: 0;
      width: 50%;
      margin-right: 62px;
    }
  }

  &ArticleContentWrapper {
    @include sb-respond-to(medium-up) {
      width: 50%;
    }
  }

  &ArticleHeading {
    margin-bottom: $sb-spacing-s;

    @include sb-respond-to(medium-up) {
      margin-bottom: 13px;
      margin-top: -7px;
    }
  }

  &ArticleContent {
    margin-bottom: $sb-spacing-l;

    @include sb-respond-to(medium-up) {
      margin-bottom: 22px;
    }
  }

  &ViewArticlesButton {
    display: flex;
    justify-content: space-around;
    margin-top: $sb-spacing-l;
  }

  &ViewAll a {
    color: $sb-colour-text-default;
    text-decoration: none;
  }
}

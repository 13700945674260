@import '~@which/seatbelt/src/styles/styles';

.sharingOptionsVertical {
  margin-top: 30px;
  margin-bottom: 20px;
}

@include sb-respond-to(small) {
  .sharingOptionsHorizontal {
    margin-bottom: $sb-spacing-l;
  }
}

@import '~@which/seatbelt/src/styles/styles';

.visualDataCarousel {
  [class*='cardRowScrollWrapper'] {
    padding-left: 0;
    padding-right: 0;
  }

  [data-testid='button-traverse'] {
    top: calc(50% - 52px);

    svg {
      pointer-events: none;
    }
  }

  h2 {
    margin-left: 0;
    padding-bottom: $sb-spacing-l;
  }

  .imageWrapper {
    position: relative;
    border: 1px solid sb-color('sb-color-black-20');
    width: 205px;

    &:not(:last-of-type) {
      margin-right: 27px;
    }

    .button {
      position: absolute;
      top: 0;
      left: 0;
      border: none;
      border-radius: 0;
      width: 100%;
      height: 100%;
      padding: 0;
      background: transparent;
      cursor: zoom-in;
    }
  }

  .carouselWrapper {
    margin: $sb-spacing-l 0;
    width: 100%;
    padding-top: 50%;
    position: relative;
    background-color: $sb-colour-background-subtle;

    [class^='Carousel__imageMagnifyRoot'] {
      transform: none;
      top: auto;
    }

    //SB-TODO - double underscore > single underscore
    [class^='Carousel_imageMagnifyRoot'] {
      transform: none;
      top: auto;
    }

    .slider {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      margin: 0;
      padding: 0;
      width: auto;
      top: 0;
      left: 0;
      transition: transform $sb-transition-duration-300;

      @media (prefers-reduced-motion) {
        transition: none;
      }
    }
  }

  [data-testid='modalContainer'] {
    z-index: 1003;

    [data-testid='modalContent'] {
      width: 1123px;
      max-height: none;

      [data-testid='closeButton'] {
        svg {
          position: relative;
          right: -1px;
        }
      }
    }
  }
}

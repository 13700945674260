@import '~@which/seatbelt/src/styles/styles';

.card {
  position: relative;
  margin-bottom: $sb-spacing-xl;

  @include sb-respond-to(medium-up) {
    margin-bottom: $sb-spacing-3xl;
  }

  &Body {
    margin: $sb-spacing-m 0;
  }

  &Label {
    background-color: #ecedf4;
    color: $sb-colour-text-default;
    text-align: center;
    text-transform: uppercase;
    padding: $sb-spacing-xs $sb-spacing-s;
    margin-top: $sb-spacing-m;
    width: 88px;

    @include sb-respond-to(medium-up) {
      margin-top: $sb-spacing-l;
    }
  }

  &Article {
    border: $sb-border-width-s solid sb-color(sb-color-black-20);
    border-radius: $sb-border-radius-s;
    margin-bottom: $sb-spacing-m;
    height: 418px;

    @include sb-respond-to(medium-up) {
      margin-bottom: 0;
    }

    &:hover {
      box-shadow: 2px 6px 6px -2px sb-color(sb-color-black-20);
    }

    &Content {
      padding: 0 $sb-spacing-m;

      @include sb-respond-to(medium-up) {
        padding: 0 $sb-spacing-l;
      }
    }

    &Image > div {
      position: relative;
      padding-top: 50%;

      picture {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
      }

      img {
        height: 100%;
        object-fit: cover;
      }
    }
  }

  &Horizontal {
    display: grid;
    grid-template-columns: 1fr 1fr;

    &Content {
      padding: 0 0 0 $sb-spacing-m;

      h3 {
        margin: 0;
      }

      &Body {
        margin: $sb-spacing-xs 0;
      }
    }
  }
}

@import '~@which/seatbelt/src/styles/styles';

.tableWrapper {
  position: relative;
  margin: $sb-spacing-l -7.6%;
  overflow: hidden;

  @include sb-respond-to(medium-up) {
    margin-left: 0;
    margin-right: 0;
    overflow: hidden;
    margin-bottom: $sb-spacing-xl;
  }

  @include sb-respond-to(large) {
    margin-left: -14.8%;
    margin-right: -14.8%;
  }

  @include sb-respond-to(xlarge) {
    margin-left: -34.5%;
    margin-right: -17.5%;
  }
}

.scrollTableWrapper {
  position: relative;
  min-width: min-content;
  width: 100%;
}

.tableBorder {
  display: block;
  position: absolute;
  width: 15px;
  height: 100%;
  top: 0;
  right: 0;
  background: linear-gradient(90deg, hsla(0, 0%, 100%, 0), #eee);
}

.tableControl {
  picture img {
    width: 100px;
  }

  .w-table-stars {
    display: inline-flex;
    flex-wrap: nowrap;
    gap: 6px;
  }

  .w-table-star {
    color: $sb-colour-text-default;

    &.unfilled {
      color: $sb-colour-text-muted;
    }

    /*
      Temporary fix to make stars larger in firefox to match Chrome.
      To be removed when unicode stars are replaced with icons in future.
    */
    @supports (-moz-appearance: meterbar) {
      font-size: 20px;
    }
  }

  &.w-table-icon-member {
    display: inline-block;
  }

  &.w-table-icon-rounded {
    width: 40px;
    height: 40px;
    border-radius: $sb-border-radius-full;
    display: flex;
    align-items: center;
    justify-content: center;

    &.approve {
      background-color: sb-color('sb-color-teal-100');
    }

    &.disapprove {
      background-color: sb-color('sb-color-indigo-100');
    }
  }
}

td a span,
td span strong[class^='Typography'] {
  font-size: $sb-font-size-14;
  line-height: 140%;

  @include sb-respond-to(medium-up) {
    font-size: $sb-font-size-16;
  }
}

.showMoreWrapper {
  display: inline-grid;
  grid-template-columns: 100%;
  width: 100%;
  max-width: max-content;
}

@import '~@which/seatbelt/src/styles/styles';

.affiliateDisclaimerWrapper {
  position: relative;
  padding-top: $sb-spacing-m;

  @include sb-respond-to(medium-up) {
    text-align: left;
    padding-top: $sb-spacing-l;
  }
}

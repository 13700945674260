@import '~@which/seatbelt/src/styles/styles';

.resubBanner {
  background-color: $sb-colour-background-brand-purple;

  &Copy {
    padding: $sb-spacing-m 0 $sb-spacing-s;

    @include sb-respond-to(medium-up) {
      padding: $sb-spacing-xl 0;
    }
  }

  &Grid {
    position: relative;
  }

  &Actions {
    align-self: end;
    padding: 0 0 $sb-spacing-m;

    @include sb-respond-to(large) {
      align-items: end;
      height: 100%;
      justify-items: end;
      padding: $sb-spacing-m 0;
      position: relative;
    }
  }

  &CloseWrapper {
    height: 20px;
  }

  &Close {
    background: transparent;
    border: unset;
    border-radius: $sb-border-radius-full;
    cursor: pointer;
    height: 37px;
    position: absolute;
    right: -16px;
    top: 8px;
    width: 37px;
    z-index: 1;

    &:focus,
    &:hover {
      outline: 1px solid white;
      border: $sb-border-width-m solid $sb-colour-border-action-default;
    }

    @include sb-respond-to(medium-up) {
      right: 0;
    }

    &Icon {
      position: absolute;
      margin: auto;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  &ButtonWrapper {
    align-self: end;
  }

  &Button {
    background-color: $sb-colour-background-brand-red;
    border: 1px solid $sb-colour-background-brand-red;
    justify-self: end;
    transition: background-color 0.3s ease-out;

    &:focus {
      background-color: $sb-colour-background-default;
      border: 1px solid $sb-colour-background-action-primary-default;
      color: $sb-colour-text-default;
    }

    &:hover {
      background-color: $sb-colour-background-action-signup-hover;
      border: 1px solid $sb-colour-background-action-signup-hover;
      color: $sb-colour-text-inverse;
    }
  }
}

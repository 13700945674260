@import '~@which/seatbelt/src/styles/styles';

.embedBrightcove {
  padding-top: 56.25%;
  position: relative;

  video {
    width: 100%;
    max-width: 100%;
  }

  :global(.video-js) {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
  }

  .vjs-layout-medium .vjs-menu-button-popup .vjs-menu .vjs-menu-content {
    max-height: none;
  }

  ul.vjs-menu-content {
    margin: 0;

    li.vjs-menu-item {
      cursor: pointer;
      color: white;
      font-size: 0.75rem;
      text-transform: capitalize;
      max-height: none;

      &::before {
        display: none;
      }
    }
  }
}

@import '~@which/seatbelt/src/styles/styles';

.container {
  position: relative;
}

.black {
  color: $sb-colour-text-default;
}

.centered {
  text-align: center;
}

.heading {
  margin: 0;
  padding: 0 0 30px;
  text-align: center;

  @include sb-respond-to(large) {
    padding: 0 0 63px;
  }
}

.default {
  font-size: 21px;
  line-height: 30px;
  margin: 0 auto;
  width: 100%;

  @include sb-respond-to(medium-up) {
    font-size: $sb-font-size-28;
    line-height: 34px;
    margin: 0;
    max-width: 100%;
    padding: 0 0 42px;
  }
}

.features {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  .text {
    text-align: center;
  }

  @include sb-respond-to(medium-up) {
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-around;
    max-width: 1052px;
    margin: 0 auto;
  }

  .feature {
    padding-bottom: 20px;

    @include sb-respond-to(medium-up) {
      padding-bottom: 0;
      width: 256px;
    }

    &:last-child {
      padding-bottom: 0;
    }

    & .text {
      margin-left: auto;
      margin-right: auto;
      width: 180px;

      @include sb-respond-to(medium-up) {
        font-size: 18px;
        line-height: 28px;
      }

      @include sb-respond-to(large) {
        padding: 0 20px;
        width: auto;
      }
    }
  }
}

.image {
  display: block;
  height: 79px;
  margin: 0 auto $sb-spacing-s;
  width: 98px;

  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    height: 105px;
    margin: 0 auto 20px;
    width: 133px;
  }

  @include sb-respond-to(large) {
    height: 102px;
    width: 127px;
  }
}

.cta {
  padding: 20px 0 6px;
  width: 100%;

  @include sb-respond-to(medium-up) {
    text-align: center;
  }

  @media only screen and (min-width: 768px) and (max-width: 1025px) {
    padding: $sb-spacing-2xl 0 0;
  }

  @include sb-respond-to(large) {
    padding: $sb-spacing-2xl 0 8px;
  }
}

@import '~@which/seatbelt/src/styles/styles';

.container {
  margin-top: 20px;

  .list {
    padding: 0;
    margin: 0;
  }

  .offersList {
    padding: 0;
    margin: 0;
  }

  .listItem {
    list-style-type: none;
    position: relative;
  }

  .offersListItem {
    list-style-type: none;
    margin: $sb-spacing-s 0;
  }

  .card {
    border: $sb-border-width-s solid $sb-colour-border-muted;
    border-radius: $sb-border-radius-s;
    margin-bottom: $sb-spacing-m;
  }

  .wrapper {
    padding: 21px 21px 21px;
  }

  .summary,
  .summaryImage {
    margin-bottom: $sb-spacing-m;
  }

  .saveHeader {
    flex-basis: unset;
    flex-shrink: 1;
    flex-grow: 1;

    display: flex;
    justify-content: space-between;
  }

  .productTitle {
    margin: 0;
  }

  .productImage {
    width: 100%;
  }

  .imageContainer {
    width: 100%;
    max-width: 180px;
  }

  .safetyAlert {
    position: absolute;
    z-index: 1;
  }

  @include sb-respond-to(medium-up) {
    .summary {
      flex-wrap: nowrap;
    }

    .summaryImage {
      flex-basis: 224px;
      flex-grow: 0;
      justify-content: center;
      margin-right: 15px;
    }
  }

  .score {
    margin-right: 7px;
  }

  .badge {
    margin: 0 0 8px 0;
    position: relative;
    top: 6px;

    @include sb-respond-to(large) {
      margin: 0 8px 0 0;
      position: static;
    }
  }

  .price {
    margin-right: $sb-spacing-m;
  }

  .description {
    margin-bottom: $sb-spacing-m;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .signuplink {
    margin-bottom: 5px;

    @include sb-respond-to(small) {
      padding: 8px 15px;
    }
  }

  .loginWrapper {
    margin-top: $sb-spacing-m;
  }

  .loginLink {
    margin-left: 7px;
  }

  .section {
    margin-bottom: $sb-spacing-m;

    &.scoreAndBadges {
      display: inline-flex;
      align-items: center;

      .badgesContainer {
        display: flex;
        flex-direction: column;

        @include sb-respond-to(large) {
          align-items: center;
          flex-direction: row;
        }
      }
    }
  }
}

.badges {
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0 0 $sb-spacing-l 0;
}

.badgesContainer {
  margin-bottom: $sb-spacing-l;
}

.priceSection {
  background: olive;
}

@include sb-respond-to(medium-up) {
  .summary {
    display: flex;
    gap: 15px;

    figure.noMargin {
      margin-top: 0;
    }
  }

  .summaryImage {
    flex: 0 0 180px;
  }

  .summaryDetails {
    flex-grow: 1;
  }

  .scoreAndBadges {
    flex: 0 0 25%;
  }
}

.hiddenProduct {
  display: inline-block;
  height: $sb-spacing-l;
  background-color: $sb-colour-background-muted;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%23929292FF' stroke-width='2' stroke-dasharray='5' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");
}

.hiddenManufacturer {
  @extend .hiddenProduct;
  width: 60%;
  margin-bottom: 8px;
}

.hiddenModel {
  @extend .hiddenProduct;
  width: 90%;
}

.colourSwatch {
  text-align: left;
  margin-top: -8px;
  margin-bottom: $sb-spacing-l;
}

.radioGroup {
  justify-content: flex-start;
}

.keyTestResultsList {
  @include sb-reset-list;
}

.keyTestResultsListItem {
  margin-bottom: $sb-spacing-s;
}

.padlockIcon {
  margin-right: 13px;
}

.externalLinkIcon {
  margin: 0 0 0 $sb-spacing-s;
}

.runningCost {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 10px;
  background: $sb-colour-background-subtle;
  border-top: $sb-border-width-s solid $sb-colour-border-muted;
  border-radius: $sb-border-radius-s;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding: $sb-spacing-s $sb-spacing-l;

  @include sb-respond-to(medium-up) {
    flex-direction: row-reverse;
  }

  .tooltip {
    margin: 0 0 0 $sb-spacing-s;
  }
}

.flexCentre {
  display: flex;
  align-items: center;
}

.link {
  text-decoration: none;
  color: $sb-colour-text-link-default;
}

.leftMargin {
  margin-left: $sb-spacing-s;
}

.typicalPriceLabel {
  color: $sb-colour-text-muted;
  display: inline-block;
  font-weight: 600;
}

.listingLink {
  display: block;
  margin: 16px 0 21px;

  @include sb-respond-to(medium-up) {
    margin: 16px 0;
  }
}

.memberListingLink {
  display: block;
  margin: 16px 0 0;

  @include sb-respond-to(medium-up) {
    display: inline-block;
    margin: 0 0 0 16px;
  }
}

.skipToCompareButton {
  bottom: $sb-spacing-s;
  right: $sb-spacing-s;
}

.compareCheckbox {
  position: relative;
  display: flex;
  margin-top: $sb-spacing-m;
  @include sb-respond-to(medium-up) {
    justify-content: center;
  }
}

.linkStyleFeatureEnabled {
  text-decoration: underline;
  font-family: $sb-font-family-medium;
  color: $sb-colour-text-default;
  text-decoration-color: $sb-colour-background-brand-red;
  text-underline-offset: 3px;
  text-decoration-thickness: 1px;

  @include sb-respond-to(medium-up) {
    text-underline-offset: 4px;
  }

  &:hover {
    color: $sb-colour-text-default;
    text-decoration-thickness: 3px;
  }

  span {
    font-family: inherit;
    font-size: 14px;
    background-image: none;

    @include sb-respond-to(medium-up) {
      font-size: 16px;
    }
  }

  &.large {
    span {
      font-size: 16px;

      @include sb-respond-to(medium-up) {
        font-size: 18px;
      }
    }
  }

  svg path {
    fill: $sb-colour-text-default;
  }
}

@import '~@which/seatbelt/src/styles/styles';

.searchContainer {
  display: flex;
  max-width: 1440px;
  margin: 0 $sb-spacing-l;

  @include sb-respond-to(medium-up) {
    margin: 0 43px;
  }

  @media only screen and (min-width: 1460px) {
    margin-left: auto;
    margin-right: auto;
  }
}

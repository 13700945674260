@import '~@which/seatbelt/src/styles/styles';

.topicsSection {
  max-width: 1440px;
  margin: 0 auto;
  overflow: hidden;
  width: 100%;

  &One {
    margin-bottom: $sb-spacing-l;

    @include sb-respond-to(large) {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
    }
  }

  &Column {
    @include sb-respond-to(large) {
      display: flex;
      width: 50%;
    }

    &One {
      flex-direction: column;
    }
  }

  &TopCardWrapper {
    @include sb-respond-to(large) {
      display: flex;
      flex-direction: row;
    }
  }

  &Spacing {
    @include sb-respond-to(large) {
      margin: 0 $sb-spacing-l 0 0;
      flex: 50%;
      justify-content: stretch;
      display: flex;
    }
  }

  &BottomCardSpacing {
    @include sb-respond-to(large) {
      margin: $sb-spacing-l $sb-spacing-l 0 0;
      height: 100%;
    }
  }

  &ViewArticlesButton {
    text-align: center;
  }

  &LargeCard {
    margin: auto;
    @include sb-respond-to(large) {
      height: 694px;
      width: 100%;
      a {
        height: 100%;
      }
    }
  }

  &TopCard {
    margin: auto;
    @include sb-respond-to(large) {
      a {
        height: 100%;
      }

      height: 389px;
      width: 100%;
    }
  }

  &BottomCard {
    margin: auto;
    @include sb-respond-to(large) {
      [data-testid='article-card-text'],
      a {
        height: 100%;
      }

      height: 284px;
      width: 100%;
    }
  }

  .mobileCarousel {
    margin-bottom: $sb-spacing-l;

    [class*='cardRowScrollWrapper'] {
      padding-left: 0;
      padding-right: 11px;
    }

    article {
      width: 278px;
      margin-right: $sb-spacing-s;
    }
  }
}

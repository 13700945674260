@import '~@which/seatbelt/src/styles/styles';

.piRole {
  display: block;

  p {
    margin: 0;
  }

  &Button {
    margin-top: $sb-spacing-s;
    font-size: $sb-font-size-16;

    &:not(:last-child) {
      margin-right: $sb-spacing-s;
    }
  }
}

@import '~@which/seatbelt/src/styles/styles';

.CRToolEntryPoint {
  border: $sb-border-width-s solid sb-color('sb-color-black-20');
  border-radius: $sb-border-radius-s;
  padding: $sb-spacing-l;
  margin-top: $sb-spacing-m;

  &Label {
    display: block;

    @include sb-respond-to(medium-up) {
      display: inline-block;
    }
  }

  &Input {
    background-image: url("data:image/svg+xml;utf8,<svg fill='%231866C8' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path d='M1.437,3.164 C1.48109554,3.14866667 3.50709554,5.242 7.515,9.444 L14.023,3.141 L15.414,4.577 L7.47,12.274 L1.77635684e-15,4.554 C0.91390446,3.64266667 1.39290446,3.17933333 1.437,3.164 Z'/></svg>");
    background-position: calc(100% - 15px) 50%;
    background-repeat: no-repeat;
    background-size: 16px;
    appearance: none;
    background-color: $sb-colour-background-default;
    border: $sb-border-width-s solid sb-color('sb-color-black-60');
    border-radius: $sb-border-radius-s;
    padding: $sb-spacing-s 38px $sb-spacing-s $sb-spacing-m;
    line-height: 22px;
    min-height: 44px;
    margin-bottom: $sb-spacing-m;
    margin-top: $sb-spacing-s;
    white-space: normal;
    width: 100%;

    @include sb-respond-to(medium-up) {
      display: block;
      margin-right: $sb-spacing-m;
      width: 362px;
    }
  }

  &Button {
    justify-content: center;
    width: 100%;

    @include sb-respond-to(medium-up) {
      display: inline-block;
      width: auto;
    }
  }
}

@import '~@which/seatbelt/src/styles/styles';

.wHeaderWrapper {
  text-align: left;
  padding-top: $sb-spacing-xl;

  .wHeaderWrapperDate {
    grid-area: wHeaderWrapperDate;
    margin-bottom: $sb-spacing-xs;
  }

  .headline {
    grid-area: headline;
    margin-bottom: $sb-spacing-m;
  }

  .wHeaderStandfirst {
    grid-area: wHeaderStandfirst;
    margin-bottom: $sb-spacing-l;
  }

  .wHeaderStandfirst.wHeaderStandfirstMarginOffset {
    margin-bottom: $sb-spacing-l;
  }
}

.wInnerWrapper {
  @include sb-respond-to(xlarge) {
    max-width: 980px;
    margin-left: auto;
    margin-right: auto;
  }
}

.newsArticle {
  display: grid;
  grid-template-areas:
    'headline headline'
    'wHeaderStandfirst wHeaderStandfirst'
    'wHeaderWrapperDate wHeaderWrapperDate';
  grid-template-columns: repeat(2, minmax(0, 1fr));

  @include sb-respond-to(medium-up) {
    grid-template-areas:
      'headline headline headline headline headline headline headline headline headline headline headline headline'
      'wHeaderStandfirst wHeaderStandfirst wHeaderStandfirst wHeaderStandfirst wHeaderStandfirst wHeaderStandfirst wHeaderStandfirst wHeaderStandfirst wHeaderStandfirst wHeaderStandfirst wHeaderStandfirst wHeaderStandfirst'
      'wHeaderWrapperDate wHeaderWrapperDate wHeaderWrapperDate wHeaderWrapperDate wHeaderWrapperDate wHeaderWrapperDate wHeaderWrapperDate wHeaderWrapperDate wHeaderWrapperDate wHeaderWrapperDate wHeaderWrapperDate wHeaderWrapperDate';
  }

  .wHeaderWrapperDate {
    margin-bottom: $sb-spacing-l;
  }

  .wHeaderStandfirst {
    margin-bottom: $sb-spacing-s;
  }
}

.article-template-no-hero {
  .wHeaderStandfirst.wHeaderStandfirstMarginOffset {
    @include sb-respond-to(medium-up) {
      margin-bottom: 33px;
    }
  }
}

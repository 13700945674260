@import '~@which/seatbelt/src/styles/styles';

.wrapper {
  width: 83%;
  margin: auto;
  display: flex;
  flex-flow: column nowrap;

  @include sb-respond-to(large) {
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .container {
    @include sb-respond-to(large) {
      height: 350px;
      margin: 0 136px 0 0;
    }

    .heading {
      margin: 35px 0 18px;
    }

    .code {
      margin: 18px 0 14px;
    }

    .text {
      margin: 14px 0 $sb-spacing-xl;
    }
  }

  .image {
    margin: $sb-spacing-xl auto 92px;
    width: 100%;
    max-width: 400px;

    @include sb-respond-to(large) {
      margin: 90px 0 90px;
    }
  }
}

@import '~@which/seatbelt/src/styles/styles';
@import './styles/variables';
@import './styles/mixins/wrapper';

// App-wide global classes

body {
  font-family: $sb-font-family-regular;
  color: $sb-colour-text-default;
  -webkit-print-color-adjust: exact;
}

.root-app {
  position: relative;
  min-height: 100vh;
  z-index: 1;
}

.sr-only {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;

  &:focus {
    position: static;
    width: auto;
    height: auto;
  }
}

.w-wrapper,
.w-page-wrapper {
  @include w-page-wrapper(false);

  // @TODO: What do we do here to handle theme toggle?
  // Previously had mixin to set defult theme font-family and weight via SB to open sans semiold
  // Interim fix using 600 - We can inline national font here after fonts switch
  b,
  strong {
    @include sb-font-medium;
  }
}

.user-feedback-iframe {
  // The src attribute on the iframe is set by OneTrust only when the correct cookies are accepted
  // See https://my.onetrust.com/articles/en_US/Knowledge/UUID-3dc06342-ff6b-3578-5024-6d7c05d03283
  // This CSS removes the empty space & hides from assistive tech when the user rejects cookies
  &:not([src]) {
    display: none;
  }
}

@import '~@which/seatbelt/src/styles/styles';

.heroImageWrapper {
  margin: 0 -7.6% $sb-spacing-l;

  @include sb-respond-to(medium-up) {
    margin-left: 0;
    margin-right: 0;
  }

  // Prevent caption from touching viewport edges on small screens
  figcaption {
    padding: 0 $sb-spacing-s;

    @include sb-respond-to(medium-up) {
      padding: 0;
    }
  }
}

@import '~@which/seatbelt/src/styles/styles';

.embedGallery {
  margin-top: $sb-spacing-m;

  // Display full width in articles < 768px
  // Over-ride styles coming via .aricle-wrapper figure
  figure[class^='ImageGallery__figure'] {
    display: grid;
    margin: 0 -7.6%;

    @include sb-respond-to(medium-up) {
      margin: 0;
    }
  }

  //now we have moved to module.scss in seatbelt we shouldn't use double underscore
  figure[class^='ImageGallery_figure'] {
    display: grid;
    margin: 0 -7.6%;

    @include sb-respond-to(medium-up) {
      margin: 0;
    }
  }

  // Pad image caption and count < 768px
  div[class^='ImageGallery__caption'] {
    margin-left: 7.6%;
    margin-right: 7.6%;

    @include sb-respond-to(medium-up) {
      margin-left: 0;
      margin-right: 0;
    }
  }

  // Over-ride styles coming via .aricle-wrapper figure
  div[class^='slideInner'] > figure {
    display: block;
    margin: 0;
  }
}

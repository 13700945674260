.full-bleed {
  @include fullBleedMarginDeclaration($pageMargin-sm);

  @include sb-respond-to(medium-up) {
    @include fullBleedMarginDeclaration($pageMargin-md);
  }

  @include sb-respond-to(large) {
    @include fullBleedMarginDeclaration($pageMargin-lg);
  }
}

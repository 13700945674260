@import '~@which/seatbelt/src/styles/styles';

.moreButtonWrapper {
  pointer-events: none;
  position: relative;
  z-index: 1;
  padding: $sb-spacing-2xl 0 $sb-spacing-l;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255),
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 34%,
    rgba(255, 255, 255, 1) 35%,
    rgba(255, 255, 255, 1) 45%,
    rgba(255, 255, 255, 0) 100%
  );

  &OmitBg {
    background: none;
  }

  &::before {
    content: '';
    z-index: -2;
    position: absolute;
    width: 100%;
    height: 1px;
    left: 0;
    top: 70px;
    background-color: $sb-colour-border-default;
  }

  &-left {
    text-align: left;
  }

  &-center {
    text-align: center;
  }

  &Button {
    justify-content: center;
    min-width: 162px;
    pointer-events: auto;
  }
}

@import '~@which/seatbelt/src/styles/styles';

$card-list-grid-gap: 3%;
$card-width: calc((100% - ($card-list-grid-gap * 3)) / 4);

.baseTray {
  background-color: $sb-colour-background-default;
  bottom: 0;
  box-shadow: 2px 6px 18px -2px $sb-colour-border-emphasised;
  left: 0;
  position: fixed;
  width: 100%;
  z-index: sb-z-index('sb-z-index-10');

  @include sb-respond-to(xlarge) {
    display: flex;
    justify-content: center;
  }
}

.trayGrid {
  @include sb-respond-to(small) {
    margin: 0;
  }

  @include sb-respond-to(xlarge) {
    width: 1440px;
  }
}

.list {
  padding: 0;
  margin: 0;

  @include sb-respond-to(medium-up) {
    display: flex;
    grid-column-gap: $card-list-grid-gap;
    list-style-type: none;
    position: relative;
    width: 100%;
  }

  li {
    display: flex;
    margin: 24px $sb-spacing-s $sb-spacing-s;
    position: relative;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    @include sb-respond-to(medium) {
      margin: 20px 0;
      flex-direction: column;
      width: $card-width;
    }

    @include sb-respond-to(large) {
      margin: $sb-spacing-l 0;
      width: 22.75%;
    }
  }
}

.itemTextWrapper {
  display: flex;
  flex-direction: column;
}

.buttonWrapper {
  align-items: center;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: $sb-spacing-s 0 $sb-spacing-l;
  position: relative;
  text-align: center;

  @include sb-respond-to(small) {
    margin: 0 6.6%;
  }

  @include sb-respond-to(medium) {
    padding: $sb-spacing-l 0;

    &::before {
      background-color: $sb-colour-border-muted;
      content: '';
      height: 1px;
      left: -5%;
      position: absolute;
      top: 0;
      width: 110%;
    }
  }

  @include sb-respond-to(large) {
    flex-direction: column;
    justify-content: center;
    padding: 24px 0;
  }

  > button {
    @include sb-respond-to(large) {
      display: inline-flex;
      max-height: 44px;
    }
  }
}

.compareButton {
  @include sb-respond-to(large) {
    margin-bottom: $sb-spacing-s;
  }

  &:focus {
    outline-offset: 5px;
  }
}

@include sb-respond-to(large) {
  .hiddenRefineCell,
  .compareButtonDisabled {
    margin-bottom: $sb-spacing-s;
  }
}

.compareButtonDisabled,
.compareButtonDisabled:hover {
  background-color: $sb-colour-background-disabled;
  color: $sb-colour-text-default;
  border: $sb-border-width-s solid $sb-colour-border-disabled;
  cursor: not-allowed;
}

.compareTraySkipButton {
  top: 16px;
  right: 140px;

  @include sb-respond-to(large) {
    top: 42px;
    left: -180px;
  }
}

.addProducts {
  min-height: 100px;
  width: 150px;
  box-sizing: border-box;
}

.cardRow {
  margin: 0;

  // selects card row scroll element without module suffix
  div[class^='CardRow_cardRowScroll_'],
  div[class*='CardRow_cardRowScroll_'] {
    margin: 0 6.6%;
  }
}

.card {
  width: 150px;

  @include sb-respond-to(medium-up) {
    width: 100%;
  }
}

@import '~@which/seatbelt/src/styles/styles';

.list {
  list-style: none;
  margin-bottom: $sb-spacing-l;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: $sb-spacing-s;
  row-gap: $sb-spacing-xs;
  padding: 0;
}

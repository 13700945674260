@import '~@which/seatbelt/src/styles/styles';

.grid {
  margin-top: $sb-spacing-m;

  @include sb-respond-to(medium-up) {
    margin-top: $sb-spacing-3xl;
  }

  grid-row-gap: $sb-spacing-l;
}

.textContainer {
  @include sb-respond-to(medium-up) {
    order: -1;
  }
}

.errorCode {
  margin-bottom: $sb-spacing-m;
}

.text,
.buttonLink {
  margin-bottom: $sb-spacing-l;
}

.linkList {
  @include sb-reset-list;

  @include sb-respond-to(large) {
    display: flex;
    flex-wrap: wrap;
  }
}

.linkItem {
  margin-bottom: $sb-spacing-m;

  @include sb-respond-to(large) {
    flex-basis: 50%;
  }

  &:last-child {
    margin-bottom: $sb-spacing-l;
  }
}

.image {
  max-width: 400px;
  margin: 0 auto;

  @include sb-respond-to(medium-up) {
    max-width: revert;
    margin: revert;
  }
}

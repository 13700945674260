@import '~@which/seatbelt/src/styles/styles';

.topRatedVariantTwo2366 {
  display: flex;
  justify-content: space-between;
}

.wrapper {
  display: none;
  background: $sb-colour-background-inverse;
  color: sb-color('sb-color-white');
  padding: 8px 21px;
}

.topRatedVariantOne2366 {
  text-align: center;
}

.link {
  &,
  &:hover,
  &:visited {
    color: $sb-colour-text-inverse;
  }
}

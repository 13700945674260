@import '~@which/seatbelt/src/styles/styles';

.forgotPasswordPage {
  margin-bottom: $sb-spacing-3xl;
  margin-top: $sb-spacing-xl;

  &Title {
    margin: 105px 0 $sb-spacing-m;

    @media only screen and (max-width: 767px) {
      margin: 65px 0 $sb-spacing-xl;
      width: 100%;
    }
  }

  &.error h1 {
    margin-top: $sb-spacing-xl;
  }

  &TitleCopy {
    margin-bottom: $sb-spacing-l;
  }

  &FormFooter {
    margin: $sb-spacing-xl 0 $sb-spacing-2xl;

    @media only screen and (max-width: 767px) {
      margin: $sb-spacing-xl 0 $sb-spacing-l;
    }
  }

  &ReturnUrl {
    display: inline-flex;
    padding-left: $sb-spacing-l;

    @media only screen and (max-width: 767px) {
      margin: $sb-spacing-l 0 $sb-spacing-l;
      padding: 0px;
      width: 100%;
    }

    &Link {
      font-size: $sb-font-size-18;
      width: 100%;
      text-align: center;
    }
  }

  &AnotherEmail {
    display: inline-flex;
    margin: 0 0 $sb-spacing-3xl;

    @media only screen and (max-width: 767px) {
      margin: 0 0 $sb-spacing-3xl;
      padding: 0px;
      width: 100%;
    }

    &Link {
      font-size: $sb-font-size-18;
      width: 100%;
    }
  }

  &Submit {
    width: 100%;
    display: inline-block;
    box-sizing: border-box;

    &:focus {
      //TODO: move to storybook MEXS-162
      border: $sb-border-width-m solid $sb-colour-border-action-focus;
      height: 48px;
    }

    @media only screen and (min-width: 767px) {
      width: 229px;

      &:focus {
        width: 233px;
        margin-left: -2px;
      }
    }
  }

  li p {
    margin-bottom: 0;
  }
}

@import '~@which/seatbelt/src/styles/styles';

.banner {
  background-color: $sb-colour-background-brand-purple;
  border-radius: $sb-border-radius-s;
  margin: $sb-spacing-l 3%;
  padding: $sb-spacing-m;
  text-align: left;

  @include sb-respond-to(medium-up) {
    text-align: center;
  }

  a {
    color: $sb-colour-text-link-default;

    &:visited {
      color: $sb-colour-text-link-visited;
    }
  }

  &FullWidth {
    margin: $sb-spacing-l 0;
  }
}

@import '~@which/seatbelt/src/styles/styles';

.articleHeroWrapper {
  margin: 0 -7.6% $sb-spacing-l;

  @include sb-respond-to(medium-up) {
    margin-left: 0;
    margin-right: 0;
  }
}

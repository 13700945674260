@import '~@which/seatbelt/src/styles/styles';

.newsGrid {
  grid-template-areas:
    'pageheader pageheader'
    'header header'
    'hero hero'
    'author author'
    'share share'
    'jumplinks jumplinks'
    'body body'
    'related related'
    'footer footer';
  grid-template-columns: repeat(2, minmax(0, 1fr));

  @include sb-respond-to(medium-up) {
    grid-column-gap: $grid-column-gap-tablet;
    margin: 0 $grid-gutter-tablet;

    grid-template-areas:
      'pageheader pageheader pageheader pageheader pageheader pageheader pageheader pageheader pageheader pageheader pageheader pageheader'
      '. header header header header header header header header header header .'
      '. hero hero hero hero hero hero hero hero hero hero .'
      '. author author author author author author author author author author .'
      '. jumplinks jumplinks jumplinks jumplinks jumplinks jumplinks jumplinks jumplinks jumplinks jumplinks .'
      'share body body body body body body body body body body .'
      'related related related related related related related related related related related related'
      'footer footer footer footer footer footer footer footer footer footer footer footer';
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  @media only screen and (min-width: 901px) {
    grid-template-areas:
      'pageheader pageheader pageheader pageheader pageheader pageheader pageheader pageheader pageheader pageheader pageheader pageheader'
      '. . header header header header header header header header . .'
      '. . hero hero hero hero hero hero hero hero . .'
      '. . author author author author author author author author . .'
      '. . jumplinks jumplinks jumplinks jumplinks jumplinks jumplinks jumplinks jumplinks . .'
      '. share body body body body body body body body . .'
      'related related related related related related related related related related related related'
      'footer footer footer footer footer footer footer footer footer footer footer footer';
  }

  @include sb-respond-to(large) {
    grid-column-gap: $grid-column-gap-desktop;
    margin: 0 $grid-gutter-desktop;

    grid-template-areas:
      'pageheader pageheader pageheader pageheader pageheader pageheader pageheader pageheader pageheader pageheader pageheader pageheader'
      '. header header header header header header header . . . .'
      '. hero hero hero hero hero hero hero . . . .'
      '. author author author author author author author. . . .'
      '. jumplinks jumplinks jumplinks jumplinks jumplinks jumplinks jumplinks. . . .'
      'share body body body body body body body . related related related'
      'footer footer footer footer footer footer footer footer footer footer footer footer';
  }
}

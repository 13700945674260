@import '~@which/seatbelt/src/styles/styles';

.loggedOutImage {
  background-color: sb-color('sb-color-black-05');
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%23929292FF' stroke-width='2' stroke-dasharray='5' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");
  width: 180px;
  height: 135px;
  position: relative;
  display: block;
}

.iconContainer {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.iconFlex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

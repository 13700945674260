@import '~@which/seatbelt/src/styles/styles';

.wrapper {
  margin-bottom: $sb-spacing-2xl;
}

.promoHeader,
.linkWrapper {
  margin-bottom: $sb-spacing-m;
}

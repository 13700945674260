@import '~@which/seatbelt/src/styles/styles';

.piMoreFrom {
  @include sb-respond-to(large) {
    margin: 0 auto $sb-spacing-3xl;
    padding: 0 $sb-spacing-3xl;
  }

  margin: $sb-spacing-l $sb-spacing-l $sb-spacing-xl;
  max-width: 1440px;

  &ItemWrapper {
    margin: $sb-spacing-s 0 $sb-spacing-m;

    @include sb-respond-to(large) {
      display: flex;
    }
  }
}

@import '~@which/seatbelt/src/styles/styles';

.tags {
  margin: $sb-spacing-l 0;
}

.label {
  margin-right: $sb-spacing-s;
  vertical-align: middle;
}

.list {
  @include sb-reset-list;

  display: inline;
}

.item {
  display: inline;
  margin: 0 $sb-spacing-s $sb-spacing-s 0;

  &:last-of-type {
    margin-right: 0;
  }
}

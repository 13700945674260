@import '~@which/seatbelt/src/styles/styles';

@include sb-respond-to(medium-up) {
  .hideOnArticleScroll {
    visibility: visible;
    opacity: 1;
    transition:
      opacity 0.3s,
      visibility 0.3s;

    &.isHidden {
      visibility: hidden;
      opacity: 0;
      transition:
        opacity 0.3s,
        visibility 0.3s;
    }
  }
}

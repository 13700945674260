.previewIndicator {
  background-color: rgb(254, 204, 39);
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  border: 1px solid black;
  border-top: none;
  box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.2);
  color: black;
  font-size: 16px;
  left: 50%;
  line-height: 18px;
  padding: 0 1em;
  position: fixed;
  top: 0;
  transform: translateX(-50%);
  z-index: 1100;
}

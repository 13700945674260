@import '~@which/seatbelt/src/styles/styles';

.articleSectionWrapper {
  max-width: none;
  margin-bottom: $sb-spacing-2xl;
}

.articleSection {
  margin: $sb-spacing-l;

  @include sb-respond-to(medium-up) {
    margin: 0 auto $sb-spacing-3xl;
    padding: 0 $sb-spacing-3xl;
    max-width: 1440px;
    width: 100%;
    justify-content: center;
  }

  &Header {
    max-width: 1110px;
  }

  &Articles {
    @include sb-respond-to(medium-up) {
      display: flex;
      justify-content: space-around;
    }

    > div:last-child {
      padding-bottom: 0;
    }

    padding-top: $sb-spacing-s;
  }
}

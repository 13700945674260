@import '~@which/seatbelt/src/styles/styles';

.categorySelection {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: $sb-spacing-xl;

  .label {
    margin-right: $sb-spacing-m;
    display: none;

    @include sb-respond-to(medium-up) {
      display: revert;
    }
  }

  .dropdownWrapper {
    flex: 1;

    @include sb-respond-to(medium-up) {
      flex: 0 1 auto;
    }

    select {
      width: 100%;

      @include sb-respond-to(medium-up) {
        width: auto;
      }
    }
  }
}

@mixin w-page-wrapper($include-gutter: true) {
  max-width: 1440px;

  @if $include-gutter {
    margin-left: $grid-gutter-mobile;
    margin-right: $grid-gutter-mobile;

    @include sb-respond-to(medium-up) {
      margin-left: $grid-gutter-tablet;
      margin-right: $grid-gutter-tablet;
    }

    @include sb-respond-to(large) {
      margin-left: $grid-gutter-desktop;
      margin-right: $grid-gutter-desktop;
    }
  }

  @media only screen and (min-width: 1460px) {
    margin-left: auto;
    margin-right: auto;
  }
}

@import '~@which/seatbelt/src/styles/styles';

.toolFrameworkError {
  &BulletBlack {
    &::before {
      color: $sb-colour-text-default !important;
    }
  }

  &Text {
    margin-top: $sb-spacing-l;
    margin-bottom: 0;
  }
}

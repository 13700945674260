@import '~@which/seatbelt/src/styles/styles';

.articleCard {
  margin: 0 auto;
  max-width: 390px;
  padding: 0 0 $sb-spacing-l;
  text-align: center;

  @include sb-respond-to(medium-up) {
    margin: 0;
    padding: 0 $sb-spacing-m 0;
  }

  .imgWrapper {
    max-width: 100%;
    margin: 0 auto $sb-spacing-l;
    width: 150px;
    height: 150px;
    border-radius: $sb-border-radius-full;
    overflow: hidden;

    @include sb-respond-to(large) {
      width: 200px;
      height: 200px;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &Heading {
    margin-bottom: $sb-spacing-s;
  }

  .link {
    display: block;
    margin-top: $sb-spacing-m;
  }
}

@import '~@which/seatbelt/src/styles/styles';

.emptyCompareContainer {
  max-width: 550px;
  margin: 65px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  .noAnswerIcon {
    width: 138px;
    height: 68px;
    margin-bottom: $sb-spacing-m;
  }

  .heading {
    margin-bottom: $sb-spacing-l;
  }

  .hardcodedText {
    margin-bottom: $sb-spacing-l;
  }
}

@import '~@which/seatbelt/src/styles/styles';

.associatedArticleImageLinksWrapper {
  @include sb-respond-to(medium-up) {
    margin-left: 0;
    flex: 1;

    &.largeOrderedList {
      margin-left: $sb-spacing-s;

      h2 {
        margin-left: ($sb-spacing-s * -1);
      }
    }
  }

  .multiLinkList {
    list-style-type: none;
    margin-left: 0;
    margin-top: 0;
    padding-left: 0;

    .multiLinkListItem {
      margin-bottom: $sb-spacing-m;
    }

    .imageTextWrapper {
      display: flex;

      .image {
        flex: 0 0 90px;
        display: block;
      }

      .text {
        padding-left: $sb-spacing-m;
        flex: 1;
      }
    }
  }
}

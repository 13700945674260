@import '~@which/seatbelt/src/styles/styles';

.showMoreWrapper {
  position: relative;
  margin-bottom: $sb-spacing-xl;
}

.showMore {
  position: absolute;
  width: 100%;
  bottom: -$sb-spacing-2xl;
  left: 0;
}

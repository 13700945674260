@import '~@which/seatbelt/src/styles/styles';

.marketingConsentHeading {
  margin-bottom: 15px;

  button {
    font-size: 16px;
  }
}

.marketingConsentFooter {
  margin-top: 30px;
}

.marketingConsent {
  &Type {
    width: 55px;
    text-transform: capitalize;
  }

  span,
  div {
    display: inline-flex;
  }

  label span {
    margin-right: $sb-spacing-l;
  }

  &RadioWrapper {
    margin: $sb-spacing-m 0 $sb-spacing-s;
  }

  &ValidationError {
    color: $sb-colour-text-negative;
    letter-spacing: 0;
    line-height: 25px;
  }
}

.additionalMarketingDetails {
  margin-top: $sb-spacing-m;
}

@import '~@which/seatbelt/src/styles/styles';

.registrationPage {
  margin-bottom: $sb-spacing-3xl;

  & h1 {
    margin: $sb-spacing-s 0;
  }

  &Login {
    margin-bottom: $sb-spacing-m;
  }

  &FormField {
    margin-bottom: $sb-spacing-l;
  }

  &Submit {
    margin: $sb-spacing-l 0 $sb-spacing-xl;

    &:focus {
      //TODO: move to storybook MEXS-162
      border: $sb-border-width-m solid $sb-colour-border-action-focus;
      height: 48px;
      margin-top: 23px;
      margin-left: -2px;
      margin-bottom: 38px;
    }
  }

  &Questions p {
    margin-bottom: $sb-spacing-l;
  }
}

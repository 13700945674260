@import '~@which/seatbelt/src/styles/styles';

.row {
  position: relative;
}

.tableData {
  border-top: none;
  border-bottom: none;

  &:last-of-type {
    border-right: none;
  }

  &Instructions {
    background-color: $sb-colour-background-default;
  }
}

.productScoreGauge {
  display: flex;
  justify-content: center;
  position: relative;
}

.price {
  margin-top: $sb-spacing-l;
}

.badgeSpacer {
  height: 68px;
}

.radioGroup {
  justify-content: center;
}

.offersList {
  padding: 0;
  margin: $sb-spacing-l 0 0;
}

.offersListItem {
  list-style-type: none;
  margin: $sb-spacing-s 0;
}

.typicalPriceLabel {
  color: $sb-colour-text-muted;
  display: inline-block;
  margin: 0 0 0 $sb-spacing-s;
}

.link {
  margin-left: $sb-spacing-s;
}

.saveButton {
  display: flex;
  justify-content: center;
  padding: 0 0 $sb-spacing-m;
}

.modal {
  z-index: 2000;
}

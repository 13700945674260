@import '~@which/seatbelt/src/styles/styles';
@import '../../../styles/mixins/wrapper';

.categoryHeroBleed {
  background-color: $sb-colour-background-muted;
}

.sectionGreyBleed {
  background-color: $sb-colour-background-muted;
}

.productHubGrid {
  @include w-page-wrapper(true);
}

.brandSection,
.categoryTypesSection {
  margin-bottom: $sb-spacing-2xl;

  @include sb-respond-to(large) {
    margin-bottom: $sb-spacing-3xl;
  }
}

.adviceSection {
  margin-bottom: $sb-spacing-xl;
}

.categoryHeading {
  margin-bottom: $sb-spacing-2xl;

  @include sb-respond-to(large) {
    margin-bottom: $sb-spacing-3xl;
  }

  > div {
    align-items: center;
  }
}

.categoryDescription {
  text-wrap: balance;
}

.subscribeSection {
  margin-top: $sb-spacing-2xl;

  @include sb-respond-to(large) {
    margin-top: $sb-spacing-3xl;
  }
}

@import '~@which/seatbelt/src/styles/styles';

.newsletterSignUpWithImage {
  .newsletterSignup {
    @include sb-respond-to(medium-up) {
      margin: $sb-spacing-l 0;
    }
  }

  .newsletterWrapper {
    align-self: center;
  }

  .imageGridItem {
    align-self: center;
    position: relative;
    height: 100%;

    @include sb-respond-to(medium-up) {
      margin-right: -200px;
    }

    @include sb-respond-to(large) {
      margin-right: revert;
    }
  }

  .imageWrapper {
    @include sb-respond-to(medium-up) {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;

      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  .image {
    background: none;
  }
}

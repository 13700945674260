@import '~@which/seatbelt/src/styles/styles';

.toolCTA {
  :global(.margin-bottom) {
    margin: 0 0 $sb-spacing-l;

    @include sb-respond-to(medium-up) {
      margin: 0 0 $sb-spacing-3xl;
    }
  }
}

@import '~@which/seatbelt/src/styles/styles';

.container {
  border: $sb-border-width-s solid $sb-colour-background-disabled;
  border-radius: $sb-border-radius-s;
  display: flex;
  flex-wrap: wrap;
}

.content {
  flex: 1;
  padding: $sb-spacing-m $sb-spacing-l $sb-spacing-l;
}

.heading {
  margin-bottom: $sb-spacing-m;
}

.form {
  display: inline-flex;
  flex-wrap: wrap;
  column-gap: 21px;
  row-gap: $sb-spacing-m;
  align-items: flex-end;
}

.postcodeField {
  flex: 1;
  max-width: 165px;
  min-width: 125px;

  margin-bottom: 0;
}

.introCopy {
  margin-bottom: $sb-spacing-s;
}

.termsCopy {
  margin-top: $sb-spacing-l;
}

.imageWrapper,
.brandImageBg {
  background: $sb-colour-background-brand-pink;
}

.imageWrapper {
  flex-basis: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;

  @include sb-respond-to(large) {
    flex: 1;
    max-width: 400px;
  }
}

.smallPrintLink span {
  font-size: 1rem;
}

@import '~@which/seatbelt/src/styles/styles';

.categoryArticlesSection {
  margin-bottom: $sb-spacing-xl;
}

.regulation {
  margin-bottom: $sb-spacing-3xl;
}

.title {
  margin-bottom: $sb-spacing-m;
}

.grid {
  margin-bottom: $sb-spacing-xl;
  display: grid;
  grid-column-gap: $sb-spacing-l;
  grid-row-gap: $sb-spacing-l;

  @include sb-respond-to(medium-up) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include sb-respond-to(large) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.page-view {
  margin-bottom: $sb-spacing-xl;
  text-align: center;

  @include sb-respond-to(medium-up) {
    margin-bottom: $sb-spacing-3xl;
  }

  .title {
    margin-top: $sb-spacing-xl;
    margin-bottom: $sb-spacing-m;

    @include sb-respond-to(medium-up) {
      margin-bottom: $sb-spacing-l;
    }
  }
}

@import '~@which/seatbelt/src/styles/styles';

.podcastInfo {
  &Content {
    @include sb-respond-to(medium-up) {
      padding-right: $sb-spacing-m;
    }
  }

  &Header {
    margin-bottom: $sb-spacing-l;
  }

  &Title {
    margin-bottom: $sb-spacing-m;
  }

  &Player {
    text-align: center;
    margin-top: $sb-spacing-l;

    @include sb-respond-to(medium-up) {
      margin-top: 0;
    }
  }

  &Cta {
    margin-top: $sb-spacing-m;

    @include sb-respond-to(medium-up) {
      margin-top: $sb-spacing-l;
    }
  }
}

@import '~@which/seatbelt/src/styles/styles';
@import '../../../../styles/mixins/wrapper';
@import '../../../../styles/mixins/link-animation';

.grid {
  @include w-page-wrapper(false);

  @include sb-respond-to(xlarge) {
    max-width: 1440px;
  }
}

.reviews {
  &Wrapper {
    padding-bottom: $sb-spacing-xl;

    @include sb-respond-to(large) {
      padding-bottom: $sb-spacing-3xl;
    }
  }

  &Standfirst {
    margin-bottom: $sb-spacing-l;
    p a {
      color: $sb-colour-text-link-default;
      @include link-animation;
    }
  }

  &CardRow {
    margin-left: -13px; // Margin override to ensure the card-row aligns correctly with text
    margin-bottom: $sb-spacing-l;

    div[class^='CardRow__cardRowScrollWrapper'] {
      // Padding overrides to match designs & ensure focus outline is visible
      padding-top: $sb-spacing-xs;
      padding-left: $sb-spacing-xs;
    }

    //SB-TODO - double underscore > single underscore
    div[class^='CardRow_cardRowScrollWrapper'] {
      // Padding overrides to match designs & ensure focus outline is visible
      padding-top: $sb-spacing-xs;
      padding-left: $sb-spacing-xs;
    }
  }

  &CardRowNew {
    margin-left: 0;
  }

  &ButtonWrapper {
    text-align: center;
  }

  &ProductCard {
    margin-left: $sb-spacing-m;
    width: 320px;
    background-color: $sb-colour-background-default;

    &:first-child {
      margin-left: 0;
    }
  }
}

@import '~@which/seatbelt/src/styles/styles';

.latestNews {
  &Name {
    margin-bottom: $sb-spacing-m;
  }

  &List {
    @include sb-reset-list;
  }

  &ListItem:not(:last-child) {
    margin-bottom: $sb-spacing-l;
  }

  &AllNews {
    margin-top: $sb-spacing-m;

    &Link {
      display: flex;

      > svg {
        position: relative;
        top: 6px;
      }
    }
  }
}

@import '~@which/seatbelt/src/styles/styles';

.piArticleDate {
  margin-bottom: $sb-spacing-l;

  &Reading,
  &Type {
    align-items: center;
    display: flex;
  }

  &Icon {
    margin-right: $sb-spacing-xs;
  }

  &Reading {
    margin-left: $sb-spacing-m;
  }
}

@import '~@which/seatbelt/src/styles/styles';

.heroImageWithText {
  align-items: center;
  background-color: $sb-colour-background-brand-pink;
  display: flex;
  flex-direction: column-reverse;

  @include sb-respond-to(medium-up) {
    flex-direction: row;
  }

  &Content {
    padding: $sb-spacing-l 6.6%;

    @include sb-respond-to(medium-up) {
      padding: 3%;
      width: 85%;
    }

    @include sb-respond-to(large) {
      width: 55%;
    }
  }

  h1 {
    font-size: $sb-font-size-rem-186;

    @include sb-respond-to(large) {
      font-size: $sb-font-size-rem-250;
    }
  }
}

@import '~@which/seatbelt/src/styles/styles';

.newsletterForm {
  &HeaderContainer {
    display: flex;
    flex-direction: column;
  }

  &Overline {
    color: $sb-colour-text-link-active;
    order: -1;
  }

  &Description {
    @include sb-respond-to(large) {
      flex: 1;
    }

    padding-bottom: $sb-spacing-l;
  }

  &DisclaimerText {
    margin-bottom: $sb-spacing-l;
  }

  &Unsubscribe {
    @include sb-text-very-small-print;

    margin-top: $sb-spacing-xs;

    @include sb-respond-to(medium-up) {
      margin-top: 0;
    }
  }

  .firstName,
  .lastName,
  .email,
  .postcode {
    min-width: max-content;

    &-input {
      @include sb-text-small-print;

      height: 44px;
    }
  }

  .signUpButton {
    margin-bottom: $sb-spacing-s;
  }
}

.emailOnly {
  .newsletterForm {
    @include sb-respond-to(medium-up) {
      &EmailForm {
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
        column-gap: 16px;

        .email {
          flex: 1;
        }
      }
    }
  }
}

.allFields {
  .postcode {
    margin-bottom: $sb-spacing-l;
  }

  @include sb-respond-to(medium-up) {
    .firstName {
      flex: 1;
    }

    .lastName {
      flex: 1;
    }

    .postcode {
      width: max-content;
      min-width: 50%;
      margin-bottom: 32px;
    }
  }

  .newsletterForm {
    &Name {
      @include sb-respond-to(medium-up) {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        column-gap: 16px;
      }
    }
  }
}

.submitError {
  @include sb-text-small-print;

  color: $sb-colour-text-negative;
  display: block;
  padding: $sb-spacing-xs 0;
}

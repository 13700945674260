@import '~@which/seatbelt/src/styles/styles';

.multiLinkBlocks {
  &Grid {
    display: grid;
    grid-auto-rows: unset;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    margin: 0;
    padding: 0;

    @include sb-respond-to(small) {
      > article {
        margin-bottom: $sb-spacing-xl;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    @include sb-respond-to(medium) {
      grid-gap: $sb-spacing-xl;
      grid-template-columns: 1fr 1fr;
    }

    @include sb-respond-to(large) {
      grid-gap: $sb-spacing-3xl;
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}

// Overridding SB styles
.card {
  [class^='MultiLinkBlock_multiLinkList_'],
  [class^='MultiLinkBlock_multiLinkListItem_']:last-child {
    margin-bottom: 0;
  }
}

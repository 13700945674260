@import '~@which/seatbelt/src/styles/styles';

.conversionBanner {
  align-items: center;
  background-color: #f7f7f7;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 2px 3px 0 rgba(153, 153, 153, 1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 20px 25px;

  @include sb-respond-to(medium-up) {
    flex-direction: row;
  }

  .conversionBannerContent {
    @include sb-respond-to(medium-up) {
      margin-right: 25px;
    }
  }

  .conversionBannerTitle {
    @include sb-font-size-line-height(30px, 37px);
  }

  .conversionBannerText,
  .conversionBannerTextMobile {
    @include sb-font-size-line-height(14px, 19px);
    font-family: $sb-font-family-regular;
  }

  .conversionBannerText {
    color: $sb-colour-text-muted;
    display: none;

    @include sb-respond-to(medium-up) {
      display: block;
    }

    @include sb-respond-to(large) {
      display: inline-block;
    }
  }

  .conversionBannerTextMobile {
    color: $sb-colour-text-default;
  }

  .conversionBannerLink {
    background: $sb-colour-background-brand-red;

    flex-shrink: 0;
    margin-top: 20px;

    @include sb-respond-to(medium-up) {
      margin-top: 0;
    }

    &:hover {
      background: $sb-colour-background-brand-red;
    }

    svg {
      margin: 0 0 0 $sb-spacing-xl;
    }
  }
}

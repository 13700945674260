@import '~@which/seatbelt/src/styles/styles';

.textArea {
  margin-top: $sb-spacing-l;

  @include sb-respond-to(medium-up) {
    margin: $sb-spacing-3xl 0;
  }

  &Description {
    margin-top: $sb-spacing-m;
  }

  &Button {
    margin-top: $sb-spacing-m;
  }
}

.image {
  background: none;
}

.imageContainer {
  align-self: center;
}

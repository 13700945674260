@mixin link-animation {
  position: relative;
  text-decoration: none;
  background-image: linear-gradient(currentColor, currentColor);
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: 100% 1px;

  &:hover {
    background-size: 0% 1px;
  }

  @media (hover: hover) {
    transition: background-size 150ms ease 300ms;
  }
}

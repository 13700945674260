@import '~@which/seatbelt/src/styles/styles';

.wrapper {
  position: relative;
  z-index: 0;
}

.image {
  max-width: 120px;
  margin: 0 auto $sb-spacing-m;
}

.safetyAlert {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

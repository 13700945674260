@import '~@which/seatbelt/src/styles/styles';

.heroWrapper {
  background-color: #c1e8f9;
  max-width: 1440px;
  margin: 0 0 $sb-spacing-xl;

  @include sb-respond-to(large) {
    min-height: 376px;
    margin: 0 auto $sb-spacing-3xl;
  }

  @include sb-respond-to(medium-down) {
    margin-left: 0;
    margin-right: 0;
  }

  [data-testid='picture-container'] {
    @include sb-respond-to(medium-down) {
      overflow: initial;
    }
  }

  &ImageContainer {
    div {
      height: 100%;
      display: none;

      @include sb-respond-to(large) {
        display: block;
      }
    }

    img {
      @include sb-respond-to(large) {
        height: 100%;
        display: block;
      }
    }
  }

  .content {
    padding: $sb-spacing-l;

    @include sb-respond-to(large) {
      max-width: 1440px;
      padding: $sb-spacing-l $sb-spacing-xl $sb-spacing-l $sb-spacing-3xl;
    }

    @include sb-respond-to(medium-up) {
      padding: $sb-spacing-xl $sb-spacing-xl $sb-spacing-xl $sb-spacing-3xl;
    }
  }

  &Basic {
    height: 98px;
    justify-content: center;
    padding-bottom: 0;
    min-height: auto;

    @include sb-respond-to(medium-up) {
      height: 126px;
    }

    .content {
      @include sb-respond-to(medium-up) {
        padding: 0;
      }

      align-items: center;
      display: flex;
      justify-content: center;
      padding: 0 $sb-spacing-l;
      margin-bottom: 0;
      width: 100%;

      > div {
        width: 100%;
        margin: auto;
        justify-content: center;
      }
    }
  }
}

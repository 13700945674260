@import '~@which/seatbelt/src/styles/styles';

.mainTitle,
.section {
  margin: $sb-spacing-l 0;
}

.subTitle {
  margin: $sb-spacing-m 0;
}

.repoName {
  display: inline-block;
  margin: 0 0 $sb-spacing-s 0;
}

.mainGrid {
  margin-bottom: $sb-spacing-l;
}

.ciBadge {
  vertical-align: sub;
}

@import '~@which/seatbelt/src/styles/styles';

.pageheader {
  grid-area: pageheader;
}

.header {
  grid-area: header;
}

.author {
  grid-area: author;
}

.share {
  grid-area: share;
}

.jumplinks {
  grid-area: jumplinks;
}

.hero {
  grid-area: hero;
}

.body {
  grid-area: body;
}

.related {
  grid-area: related;
}

.footer {
  grid-area: footer;
}
